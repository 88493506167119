<template>
  <LayoutDefault>
    <router-view :key="$route.name" />
  </LayoutDefault>
</template>

<script>
/*
Stripe:
  https://github.com/stripe/stripe-js

  "To best leverage Stripe’s advanced fraud functionality, ensure that Stripe.js is loaded on every page, not just your checkout page."
  "This allows Stripe to detect anomalous behavior that may be indicative of fraud as customers browse your website."

  due to the security advice from Stripe(as read above), this module is "imported as a side effect"
  this means the entire Stripe.js library does not get included in the output bundle,
  but instead gets inserted at the root as a <script>

  !! update: moved from main.js to here
  !! this way it will still be inserted at the root, but only for admins (due to route guards)
*/
import "@stripe/stripe-js";

export default {
  name: "AdminPage",
  page: {
    title: "Admin",
  },
};
</script>

<style scoped></style>
